import React, {  useState,useEffect } from "react";

import MobileNav from "./MobileNav";

import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import "./newheader.scss"
import HeaderLogo from "../../assets/images/logo.webp";
import logoBanner from "../../assets/images/logo22.png"
import Modal from "react-bootstrap/Modal";
import image from "../../assets/images/1-year-saudi-visa.jpg";


const NewHeader = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const resizeWindow = () => {
    setWindowWidth(window.screen.width);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", resizeWindow);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (<>
        {windowWidth > 768 ?   null : (<div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",background:"#118dff",padding:"5px"}}>
         <a href="tel:(773) 465-8500">
          <span style={{color:"white",fontSize:"20px" , fontWeight:600}} >
            CALL US (773) 465-8500
          </span>
         </a>
         </div>)}

        <header style={windowWidth > 768 ? null : { position: isScrolled ? "fixed" : "relative", top: 0, width: "100%", zIndex: 1000 }}>
          <div className="nav-area">
            <Link onClick={() => setShowMenu(false)} to="/" className="logo">
              <img src={HeaderLogo} alt={HeaderLogo}/>

            </Link>

            {/* for large screens */}
            <Navbar/>

            {/*<div className="text-highligh">*/}
            {/*  <h1 className="animated-heading" onClick={() => setModalShow(true)}>1 Year Saudi Visa $99</h1>*/}
            {/*</div>*/}


            {/*<div className="text-highligh">*/}
            {/*  <button onClick={() => setModalShow(true)}>1 Year Saudi Visa</button>*/}
            {/*</div>*/}

            {/* for small screens */}
            <MobileNav windowWidth={windowWidth} showMenu={showMenu} setShowMenu={setShowMenu}/>
          </div>
        </header>

        {/*<Modal*/}
        {/*    size="md"*/}
        {/*    aria-labelledby="contained-modal-title-vcenter"*/}
        {/*    centered*/}
        {/*    onHide={() => setModalShow(false)}*/}
        {/*    show={modalShow}*/}
        {/*>*/}
        {/*  <Modal.Header closeButton>*/}

        {/*  </Modal.Header>*/}
        {/*  <Modal.Body className="newModalBody">*/}
        {/*      <img  style={{ width:"100%"}} src={image} alt="10 year Saudi visa" width="auto" height="auto" />*/}
        {/*  </Modal.Body>*/}

        {/*</Modal>*/}

      </>

  );
};

export default NewHeader;
