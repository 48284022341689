// import Blogs from "pages/Blogs";
// import FAQs from "pages/FAQs";
import BlogsDetails from "containers/Blogs/BlogsDetail";
import ContactUs from "pages/ContactUs/ContactUs";
import { lazy } from "react";
import { appRoutesConst } from "./navigation";

// Components

// Home Page
const Home = lazy(() => import("../pages/Home/Home"));

// Packages
const Packages = lazy(() => import("../pages/Packages/Packages"));

//Hotels
const Hotels = lazy(() => import("../pages/Hotels/Hotels"));

// Blogs Page
const Blogs = lazy(() => import("../pages/Blogs"));

// FAqs Page
const FAQs = lazy(() => import("../pages/FAQS/FAQS"));

// FAqs Page
const Reviews = lazy(() => import("../pages/Reviews/Reviews"));

// Search Flight Page
const SearchFlight = lazy(() => import("../pages/SearchFlight/SearchFlight"));

// Image Gallary
const ImageGallary = lazy(() => import("../pages/ImageGallary/ImageGallary"));

// video Gallary
const VideoGallary = lazy(() => import("../pages/VideoGallary/VideoGallary"));

const PackagesDetail = lazy(() => import("../pages/Packages/PackagesDetail"));

// terms
const TermsConditionPage = lazy(() =>
  import("../pages/TermsCondition/TermsConditionPage")
);

const PrivacyPolicyPage = lazy(() =>
  import("../pages/PrivacyPolicy/PrivacyPolicyPage")
);

const UmrahPackages  = lazy(() => 
    import("../pages/Umrah/UmrahPacakages")
)

const Nusuk  = lazy(() =>
    import("../pages/Nusuk/Nusuk")
)
const GlobalTour  = lazy(() =>
    import("../pages/GlobalTour/GlobalTour")
)
const GlobalTourDetail  = lazy(() =>
    import("../pages/GlobalTour/GlobalTourDetailPage")
)
const TenYearVisa  = lazy(() =>
    import("../pages/TenYearVisa/TenYearVisa")
)
const TenYearSaudiTouristEVisa  = lazy(() =>
    import("../pages/TenYearSaudiTouristEVisa/TenYearSaudiTouristEVisa")
)
const Hajj2025  = lazy(() =>
    import("../pages/Hajj2025/Hajj2025")
)
const UmrahPackageDallas  = lazy(() =>
    import("../pages/UmrahPackageDallas/UmrahPackageDallas")
)
const UmrahPackageColorado = lazy(() =>
    import("../pages/UmrahPackageColorado/UmrahPackageColorado")
)
const UmrahPackageWashingtonDC = lazy(() =>
    import("../pages/UmrahPackageWashingtonDC/UmrahPackageWashingtonDC")
)
const UmrahPackageCalifornia = lazy(() =>
    import("../pages/UmrahPackageCalifornia/UmrahPackageCalifornia")
)
const UmrahPackageMichigan = lazy(() =>
    import("../pages/UmrahPackageMichigan/UmrahPackageMichigan")
)
const payment = lazy(() =>
    import("../pages/Payment/Payment")
)
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: appRoutesConst.index,
    element: Home,
    isOpenRoute: true,
  },

  // package routes
  {
    path: appRoutesConst.packages,
    element: Packages,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.packagesDetails,
    element: PackagesDetail,
    isOpenRoute: true,
    exact: true,
  },

  // hotels routes
  {
    path: appRoutesConst.hotels,
    element: Hotels,
    isOpenRoute: true,
  },

  //faqs routes
  {
    path: appRoutesConst.faqs,
    element: FAQs,
    isOpenRoute: true,
  },

  // blog routes
  {
    path: appRoutesConst.blogs,
    element: Blogs,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.blogDetails,
    element: BlogsDetails,
    isOpenRoute: true,
  },
  //  blog routes
  {
    path: appRoutesConst.reviews,
    element: Reviews,
    isOpenRoute: true,
  },
  //  search flight
  {
    path: appRoutesConst.searchflight,
    element: SearchFlight,
    isOpenRoute: true,
  },
  //images gallary
  {
    path: appRoutesConst.imagesGallary,
    element: ImageGallary,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.images,
    element: ImageGallary,
    isOpenRoute: true,
  },

  //videos gallary
  {
    path: appRoutesConst.videosGallary,
    element: VideoGallary,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.videos,
    element: VideoGallary,
    isOpenRoute: true,
  },

  // contact us
  {
    path: appRoutesConst.contactus,
    element: ContactUs,
    isOpenRoute: true,
  },
  // terms
  {
    path: appRoutesConst.termsandconditions,
    element: TermsConditionPage,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.privacypolicy,
    element: PrivacyPolicyPage,
    isOpenRoute: true,
  },

  {
    path: appRoutesConst.umrahpackages,
    element: UmrahPackages,
    isOpenRoute: true,
  },{
    path: appRoutesConst.nusuk,
    element: Nusuk,
    isOpenRoute: true,
  },{
    path: appRoutesConst.globalTour,
    element: GlobalTour,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.globalTourDetail,
    element: GlobalTourDetail,
    isOpenRoute: true,
  },

  {
    path: appRoutesConst.tenYearVisa,
    element: TenYearVisa,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.tenYearSaudiTouristEVisa,
    element: TenYearSaudiTouristEVisa,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.hajj2025,
    element: Hajj2025,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.umrahPackageDallas,
    element: UmrahPackageDallas,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.umrahPackageCalifornia,
    element: UmrahPackageCalifornia,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.umrahPackageColorado,
    element: UmrahPackageColorado,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.umrahPackageMichigan,
    element: UmrahPackageMichigan,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.umrahPackageWashingtonDC,
    element: UmrahPackageWashingtonDC,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.payment,
    element: payment,
    isOpenRoute: true,
  },
];
