import TenYearVisa from "../pages/TenYearVisa/TenYearVisa";

export const appRoutesConst = {
  index: "/",
  signin: "/signin",

  //packages
  packages: "/packages/:forType",
  packagesDetails: "/packages/:forType/:packageTitle",

  //hotels
  hotels: "/hotels",

  //faqs
  faqs: "/faqs",

  // blogs
  blogs: "/blogs",
  blogDetails: "/blogs/:title",

  //booking
  booking: "/booking",

  // reviews
  reviews: "/reviews",

  // search flight
  searchflight: "/searchflight",

  //imagesGallary
  imagesGallary: "/image-gallery",
  images: "/image-gallery/:id",

  //videosGallary
  videosGallary: "/video-gallery",
  videos: "/video-gallery/:id",

  //contact us
  contactus: "/contactus",

  //privacy policy
  privacypolicy: "/privacy-policy",

  //terms and conditions
  termsandconditions: "/terms-conditions",

  umrahpackages:"/umrah/:slug",

    nusuk:"/nusuk",

    globalTour:"/tour-packages",
    globalTourDetail:'/tour-packages-detail/:slug',
    tenYearVisa:"/10-year-saudi-visa",
    tenYearSaudiTouristEVisa:"/10-year-sauditourist-evisa",
    hajj2025:"/hajj-package",
    umrahPackageDallas: "/umrah-package-dallas",
    umrahPackageCalifornia: "/umrah-package-california",
    umrahPackageColorado: "/umrah-package-colorado",
    umrahPackageMichigan: "/umrah-package-michigan",
    umrahPackageWashingtonDC: "/umrah-package-washington-dc",
    payment: "/payment",
};
